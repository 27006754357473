<template>
  <section class="map-container">
    <iframe
      title="map"
      width="100%"
      height="450px"
      style="border:0"
      loading="lazy"
      allowfullscreen
      src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJcWIs2n1bBEcRTl8blK7xNGE&key=AIzaSyB6XSABtvCADuusuOTKxknQQYBwl8Kx7ME"
    ></iframe>
  </section>
</template>

<script>
export default {
  name: 'Map',
};
</script>

<style lang="scss" scoped>
.map-container {
  z-index: 4;
  position: relative;
  width: 80%;
  margin: 0 auto;
  padding: 0 1.25rem 2.5rem 1.25rem;
}

@media only screen and (max-width: 768px) {
  .map-container {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  .map-container {
    width: 80%;
  }
}
</style>
